import { useParams } from 'react-router-dom';
import { useAsync } from '@hometap/htco-components';
import apiWithAuth from 'utils/api';
import sentry from 'utils/sentry';
import { useCallback, useEffect } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import useTrack from 'hooks/useTrack';
import useApplicants from 'apps/application/hooks/useApplicants';
import { DOCUMENT_TAGS } from 'apps/documents/constants';

export const tagIDD = ({ documentId, trackId, tag }) => {
  const data = { status: tag };
  return apiWithAuth.v2.post(`/documents/${documentId}/actions/mark-idd/`, data).catch(error => {
    const {
      response: { status, statusText },
      message,
      config,
    } = error;
    const requestUrl = config?.url;

    sentry.logError(`Failed to tag document as ${tag}.`, {
      tags: {
        statusCode: status,
        statusText: statusText,
        trackId,
      },
      extra: {
        errorMessage: message,
        requestUrl: requestUrl,
      },
    });
  });
};

const fetchTrackDocuments = async trackId => {
  return await apiWithAuth.v2.get(`/tracks/${trackId}/documents`);
};

const useTrackDocuments = ({ trackId, fetchImmediately = false }) => {
  const { trackId: paramsTrackId } = useParams();

  if (!trackId) {
    trackId = paramsTrackId;
  }

  if (!trackId) {
    throw new Error('trackId is required for useTrackDocuments to work correctly');
  }

  /**
   * gets a list of unread documents for a given track
   * @param {String} trackId
   * @returns {Promise}
   */
  const fetchUnreadDocuments = trackId => {
    return apiWithAuth.v2.get(`/documents/unread`, {
      params: {
        track_id: trackId,
      },
    });
  };

  /**
   * Opens the unread document in a new tab and tags the document as read
   */
  const handleViewDocument = useCallback(
    unreadDocument => {
      tagIDD({ documentId: unreadDocument?.id, trackId: trackId, tag: DOCUMENT_TAGS.READ_BY_HOMEOWNER });
      window.htap.openNewTab(unreadDocument?.file_url);
    },
    [trackId],
  );

  const {
    loading,
    execute,
    error,
    results = [],
  } = useAsync(fetchTrackDocuments, { immediate: fetchImmediately, params: [trackId], executeThrow: true });
  const { user } = useCurrentUser();
  const { track } = useTrack();
  const { applicants = [], executeFetchApplicants, ...applicantsAsyncState } = useApplicants();

  const isLoading = loading || applicantsAsyncState.loading;

  useEffect(() => {
    if (track?.active_application_id) {
      executeFetchApplicants({ applicationId: track.active_application_id });
    }
  }, [track?.active_application_id, executeFetchApplicants]);

  const getApplicantFromDocumentContext = document => {
    const applicant = applicants.find(applicant =>
      (document?.contexts || []).some(context => context.context_id === applicant.person_id),
    );

    return applicant;
  };

  const getCreatedByDisplayName = ({ createdBy, isShared }) => {
    if (!createdBy) {
      // A document generated without an associated user is likely a system-generated document
      // such as an AVM report or Application summary
      return 'Hometap';
    }

    if (!isShared) {
      return 'You';
    }

    if (createdBy.user_type === 'client') {
      return createdBy.full_name;
    }

    return 'Hometap Staff';
  };

  const documents = results.map(document => {
    const isShared = document.created_by?.id !== user.id;
    const createdByDisplayName = getCreatedByDisplayName({ createdBy: document.created_by, isShared });

    return {
      ...document,
      isShared,
      createdByDisplayName: createdByDisplayName,
      uploadedFor: {
        applicant: getApplicantFromDocumentContext(document),
      },
    };
  });

  const executeFetchTrackDocuments = useCallback(() => execute(trackId), [trackId, execute]);

  return {
    error,
    documents,
    isLoading,
    executeFetchTrackDocuments,
    handleViewDocument,
    fetchUnreadDocuments,
  };
};

export default useTrackDocuments;
