import { useEffect, useMemo, useState } from 'react';
import HomeEquityHedData from './HomeEquityHedData';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import BuildProfileController from '../dashboard-build-profile/BuildProfileController';
import { useMatch, useNavigate, useLocation, Navigate, Routes, Route as RouteV6 } from 'react-router-dom-v5-compat';
import Footer from 'components/Footer/Footer';

import { HOMEOWNER_DASHBOARD, buildHomeProfileUrl, getNewHEDUrl } from 'utils/links';
import { PROFILE_INSTANCES } from 'apps/dashboard/constants/buildYourProfile';

import { useFlags } from 'launchdarkly-react-client-sdk';
import SmartCardCarousel from './smart-cards/SmartCardCarousel';
import Collapse from '@mui/material/Collapse';
import { browserTrack } from 'utils/segment';
import { getVersion } from 'utils/env';
import { useDelayHomeEquityProfile } from 'apps/dashboard/hooks';
import DashboardGoalsPage from 'apps/dashboard/components/dashboard-goals-page/DashboardGoalsPage';
import HouseCanaryLiensController from '../dashboard-house-canary-liens/HouseCanaryLiensController';
import useHouseCanaryLiens from 'apps/dashboard/hooks/useHouseCanaryLiens';
import { getHouseCanaryLienForms } from '../dashboard-house-canary-liens/utils';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import useHedPrequalRange from 'apps/dashboard/hooks/useHedPrequalRange';
import useSmartFacts from 'apps/dashboard/components/home-equity-controller/smart-cards/hooks/useSmartFacts';
import SectionLoadingWrapper from 'components/SectionLoadingWrapper';
import { useHomeValuation } from 'apps/dashboard/hooks/useHomeValuation';
import { useCurrentHome } from 'hooks/useCurrentHome';

import EquityRenovationController from './equity-renovations/EquityRenovationController';
import FinanceOptionsController from './equity-finance/FinanceOptionsController';
import FinanceGoalsController from '../dashboard-build-profile/finance-goals/FinanceGoalsController';

import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';

const SMART_CARDS_TIMEOUT = 1000;

const HomeEquityController = () => {
  const [closeSmartCards, setCloseSmartCards] = useState(true);
  const [sentSmartCardsViewedEvent, setSentSmartCardsViewedEvent] = useState(false);
  const [sentSmartCardsCompletedEvent, setSentSmartCardsCompletedEvent] = useState(false);
  const { home, hasHEDAccessToCurrentHome } = useCurrentHome();
  const navigate = useNavigate();
  const flags = useFlags();
  const { currentScenario, isLoadingHed, initialScenario, chartDataLoading } = useEquityScenarioContext();
  const { homeValuation } = useHomeValuation();

  const location = useLocation();
  const { setupProfileLater } = useDelayHomeEquityProfile();
  const { prequalRange } = useHedPrequalRange();

  const { smartFacts, setSmartCardsDate, updateSmartFacts, smartFactsLoading } = useSmartFacts();

  const hasConfirmedHomeDebts = home && home.hed_debts_confirmed;
  const { fetchAsync: fetchHCLiensAsync } = useHouseCanaryLiens({
    shouldFetch: !hasConfirmedHomeDebts && hasHEDAccessToCurrentHome,
  });

  const homeValueExists = !!homeValuation?.value;

  const HC_FLOW_ENDPOINT = 'accept-liens';
  const HC_FLOW_URL = getNewHEDUrl(`/${HC_FLOW_ENDPOINT}`);
  const matchObject = useMatch(`/dashboard/:currentTab/:page?`);

  const houseCanaryLiensData = fetchHCLiensAsync.results;
  const areScenariosLoading = chartDataLoading || !initialScenario;

  const houseCanaryLienForms = useMemo(() => {
    return getHouseCanaryLienForms(houseCanaryLiensData?.liens, initialScenario.liens);
  }, [houseCanaryLiensData?.liens, initialScenario.liens]);

  const hasRelevantHCLiens = houseCanaryLienForms.length && !areScenariosLoading;
  const shouldRedirectToHouseCanaryFlow =
    homeValueExists &&
    hasRelevantHCLiens &&
    !hasConfirmedHomeDebts &&
    matchObject?.params?.currentTab === HOMEOWNER_DASHBOARD.HOME_EQUITY &&
    matchObject?.params?.page !== HC_FLOW_ENDPOINT;

  useEffect(() => {
    if (shouldRedirectToHouseCanaryFlow) {
      return navigate(HC_FLOW_URL, { replace: true });
    }
  }, [HC_FLOW_URL, navigate, shouldRedirectToHouseCanaryFlow]);

  // Closing smart card carousel timers
  let smartCardsTimeout;
  let setSmartCardDateTimeout;

  const setLocalStorageAndTimeout = () => {
    setCloseSmartCards(false);
    smartCardsTimeout = setTimeout(() => setSmartCardsDate(new Date()), SMART_CARDS_TIMEOUT);
  };

  const handleClosing = () => {
    if (!sentSmartCardsCompletedEvent) {
      browserTrack.smartCardsCompleted({
        version: getVersion(),
      });
      setSentSmartCardsCompletedEvent(true);
    }
    smartCardsTimeout = setTimeout(setLocalStorageAndTimeout, 4000);
  };

  useEffect(() => {
    // Timeout cleanup function
    return () => {
      if (smartCardsTimeout) {
        clearTimeout(smartCardsTimeout);
      }
      if (setSmartCardDateTimeout) {
        clearTimeout(setSmartCardDateTimeout);
      }
    };
    // eslint-disable-next-line
  }, []);

  // Smart cards viewed segment event logic
  useEffect(() => {
    const initialSmartFactId = smartFacts?.[0]?.id;
    if (initialSmartFactId && !sentSmartCardsViewedEvent) {
      browserTrack.smartCardViewed({
        card_id: initialSmartFactId,
        version: getVersion(),
      });
      setSentSmartCardsViewedEvent(true);
    }
    // eslint-disable-next-line
  }, [smartFacts]);
  const isLoading = isLoadingHed || fetchHCLiensAsync.loading || smartFactsLoading || areScenariosLoading;

  const renderDashboard = () => {
    return (
      <>
        {homeValuation && (
          <>
            {flags?.showSmartCards && homeValueExists && smartFacts?.length > 1 && (
              <Collapse in={closeSmartCards} timeout={SMART_CARDS_TIMEOUT}>
                <div className="mx-auto my-0 mt-2 w-full border-0 border-b border-solid border-b-neutral-light-100">
                  <PrimaryLayoutGrid>
                    <div className="col-span-full">
                      <SmartCardCarousel cards={smartFacts} handleClosing={handleClosing} />
                    </div>
                  </PrimaryLayoutGrid>
                </div>
              </Collapse>
            )}
            <PrimaryLayoutGrid>
              <div className="col-span-full">
                <HomeEquityHedData
                  updateSmartFacts={updateSmartFacts}
                  currentEquity={currentScenario?.valuations?.currentEquity}
                  tappableEquity={prequalRange?.max || 0}
                  homeValueMissing={!homeValueExists}
                />
                <Footer theme="light" />
              </div>
            </PrimaryLayoutGrid>
          </>
        )}
      </>
    );
  };

  return (
    <ScrollToTop>
      <SectionLoadingWrapper loading={isLoading}>
        <Routes>
          <RouteV6
            path={HC_FLOW_ENDPOINT}
            element={
              <PrimaryLayoutGrid>
                <div className="col-span-full">
                  <HouseCanaryLiensController
                    lienForms={houseCanaryLienForms}
                    asOfDate={houseCanaryLiensData?.as_of_date}
                  />
                </div>
              </PrimaryLayoutGrid>
            }
          />
          <RouteV6 path="renovations/:project?/:renovationId?" element={<EquityRenovationController />} />
          <RouteV6
            path="goals"
            element={
              <PrimaryLayoutGrid>
                <DashboardGoalsPage />
              </PrimaryLayoutGrid>
            }
          />
          <RouteV6 path="finance" element={<FinanceOptionsController prequalRange={prequalRange} />} />
          <RouteV6
            path={HOMEOWNER_DASHBOARD.EDIT_PROFILE}
            element={
              home?.hed_debts_confirmed ? (
                <BuildProfileController initialEditConfig={location.state} instance={PROFILE_INSTANCES.EDIT_PROFILE} />
              ) : (
                <Navigate to={`/${HOMEOWNER_DASHBOARD.BUILD_PROFILE}`} />
              )
            }
          />
          <RouteV6 path="explore-financing" element={<FinanceGoalsController initialEditConfig={location.state} />} />
          <RouteV6
            path={HOMEOWNER_DASHBOARD.BUILD_PROFILE}
            element={
              !home?.hed_debts_confirmed || setupProfileLater ? (
                <BuildProfileController initialEditConfig={location.state} instance={PROFILE_INSTANCES.SETUP_PROFILE} />
              ) : (
                <Navigate to={getNewHEDUrl()} />
              )
            }
          />
          <RouteV6
            path="/"
            element={
              home?.hed_debts_confirmed || setupProfileLater ? (
                renderDashboard()
              ) : (
                <Navigate to={buildHomeProfileUrl()} />
              )
            }
          />
        </Routes>
      </SectionLoadingWrapper>
    </ScrollToTop>
  );
};

export default HomeEquityController;
