import apiWithAuth, { apiNoAuth } from 'utils/api';
import { browserTrack, browserIdentify } from 'utils/segment';
import { PREQUAL_TYPES } from '../constants/APIConstants';
import { getVersion } from 'utils/env';

export const createInquiry = (body = {}, prequalificationKinds) => {
  const defaultPrequalificationKinds = [PREQUAL_TYPES.ZIP, PREQUAL_TYPES.STATE, PREQUAL_TYPES.PROPERTY_TYPE];
  const params = {
    prequalification_kinds: prequalificationKinds || defaultPrequalificationKinds,
  };
  return apiNoAuth.post(`/inquiries/`, body, { params });
};

export const patchInquiry = (id, body = {}, prequalificationKinds = []) => {
  const params = {
    prequalification_kinds: prequalificationKinds,
  };
  return apiNoAuth.patch(`/inquiries/${id}/`, body, { params });
};

export const convertInquiry = (id, body = {}) => {
  return apiNoAuth.post(`/inquiries/${id}/actions/convert/`, body);
};

export const fetchPrequalification = async (id, prequalToRetrieve) => {
  const prequals = await apiNoAuth.get(`/inquiries/${id}/prequalifications`);
  if (prequals) {
    return prequals.filter(prequal => prequal?.status === 'active' && prequal?.kind === prequalToRetrieve)?.[0] || {};
  }
  return {};
};

export const fetchMultiplePrequalifications = async (id, prequalsToRetrieve) => {
  const prequals = (await apiNoAuth.get(`/inquiries/${id}/prequalifications`)) || [];
  const prequalFilter = new Set(prequalsToRetrieve);
  return prequals.filter(prequal => prequal?.status === 'active' && prequalFilter.has(prequal.kind));
};

export const sendUnqualifiedFormEvent = ({ first_name, last_name, email }) => {
  browserTrack.homeownerPreQualificationOutcomeFormSubmitted({
    first_name,
    last_name,
    email,
  });
};

export const sendInquiryConvertedSegmentEvents = ({
  first_name,
  last_name,
  email,
  isAdditionalTrack,
  adTrackingData = {},
  flags = {},
}) => {
  const userInfo = { email: email.toLowerCase(), first_name, last_name };
  // friendly_id is also a property of these segment events but unsupported at this time.
  const segmentData = {
    ...userInfo,
    version_number: getVersion(),
    ...adTrackingData,
  };
  browserTrack.homeownerTrackCreatedBrowser(segmentData);
  if (!isAdditionalTrack) {
    // since we no longer log the user in after inquiry conversion, we have to call identify
    browserIdentify(email.toLowerCase(), {
      ...userInfo,
      flags,
    });

    browserTrack.homeownerAccountCreatedBrowser(segmentData);
  }
};

export const fetchInquiries = () => {
  return apiWithAuth.v1.get('/current-user/inquiries/');
};

export const fetchInquiry = async id => {
  return await apiWithAuth.v1.get(`/current-user/inquiries/${id}/`);
};
