export const scrollIntoViewByElementId = elemId => {
  document.getElementById(elemId).scrollIntoView({
    behavior: 'smooth',
  });
};

/**
 * Verify whether the document can be tagged with a read receipt
 * @param {Document} document the document to be checked for a read receipt
 * @returns whether the document can be tagged with a read receipt
 */
export const readReceiptEligibleDocumentKinds = document => {
  // document kinds can be found in `kind_other`, `kind` or `kind_display`
  if (!document) {
    return false;
  }
  const eligibleKinds = ['Investment Disclosure'];
  const kindsToCheck = [document.kind, document.kind_display, document.kind_other];
  return kindsToCheck.some(kind => eligibleKinds.includes(kind));
};
