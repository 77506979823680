import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Grid, MuiSelect, MuiTextInput, useAsync, useWindowSize } from '@hometap/htco-components';
import { PREQUAL_TYPES } from 'apps/inquiry/constants/APIConstants';
import { createInquiry } from 'apps/inquiry/data/inquiryRequests';
import 'apps/progressive-inquiry/components/InquiryProperty.scss';
import {
  CallToAction,
  ContentPanel,
  ImageContentPanel,
  ProgressiveUITemplate,
} from 'apps/progressive-inquiry/components/ProgressiveUITemplate';
import {
  ADDRESS_ERROR_MESSAGES,
  ADDRESS_FORMAT_REGEX,
  CONTINUE_BUTTON_ID,
  DQ_PAGES,
  PAGE_TITLES,
  PREQUAL_OUTCOME,
  PROGRESSIVE_STEP_NAMES,
  scrollIntoViewByElementId,
} from 'apps/progressive-inquiry/constants/progressiveInquiryConstants';
import useGooglePlaceInput from 'hooks/useGooglePlaceInput';
import useStates from 'hooks/useStates';
import houseImg from 'images/inquiry/house2.png';
import { showNotification } from 'utils/toasts';
import sentry from 'utils/sentry';
import { parsePrequalificationsToMap } from '../../inquiry/utils/parsePrequalificationOutcomes';

const InquiryPropertyAddressVariation = ({ form, trackingData, autoFillValidAddress, step, onNext, onDequalify }) => {
  const { registerField, handleSubmit, updateFormData, formData } = form;

  const { isScreenSize } = useWindowSize();

  const onAddress = useCallback(
    (address, addressObj) => {
      updateFormData({ address, ...addressObj });
      Object.keys(addressObj).forEach(field => form?.setErrors(field, null));
    },
    [form, updateFormData],
  );

  const { statesForDropdown } = useStates();
  const { ref, isValidGoogleAddress } = useGooglePlaceInput({ onAddress, flags: ['inquiryAddressUx'] });

  const [touchedFields, setTouchedFields] = useState({
    street: false,
    unit: false,
    city: false,
    state: false,
    zip_code: false,
  });

  const { results: inquiryResults, loading, execute: postInquiry, error } = useAsync(createInquiry);

  const onSubmit = useCallback(async () => {
    const { city, state, street, zip_code, unit } = formData;
    const data = {
      address: { street, city, state, zip_code, unit },
    };

    await handleSubmit(() => {
      return postInquiry({ ...data, ...trackingData }, [PREQUAL_TYPES.ZIP, PREQUAL_TYPES.STATE]);
    })();
  }, [handleSubmit, postInquiry, trackingData, formData]);

  useEffect(() => {
    if (error) {
      showNotification({
        type: 'error',
        description: 'Something went wrong. Please refresh and try again.',
      });
      sentry.logErrorWrapper('Failed to update inquiry address', error);
    }
  }, [error]);

  useEffect(() => {
    if (inquiryResults) {
      const { prequalifications, id } = inquiryResults;
      const prequalMap = parsePrequalificationsToMap(prequalifications);
      const { StatePrequalification, ZipPrequalification } = prequalMap?.prequalifications;
      const checkFailedPrequal = outcome_code => outcome_code === PREQUAL_OUTCOME.FAIL;

      if (
        checkFailedPrequal(StatePrequalification.outcome_code) ||
        checkFailedPrequal(ZipPrequalification.outcome_code)
      ) {
        onDequalify(DQ_PAGES.LOCATION);
      } else {
        onNext(step + 1, id);
      }
    }
  }, [inquiryResults, onNext, step, onDequalify, onSubmit, formData.address]);

  useEffect(() => {
    const isAddressValid = isValidGoogleAddress || autoFillValidAddress;
    const canContinueToTheNextPage = formData.address && isAddressValid;
    if (canContinueToTheNextPage) {
      scrollIntoViewByElementId(CONTINUE_BUTTON_ID);
    }
  }, [formData?.address, isValidGoogleAddress, autoFillValidAddress]);

  const validateStreet = () => {
    if (!formData.street) {
      return ADDRESS_ERROR_MESSAGES.street.empty;
    }
    return null;
  };

  const validateCity = () => {
    const isValidAddressComponent = ADDRESS_FORMAT_REGEX.test(formData.city);
    if (!formData.city) {
      return ADDRESS_ERROR_MESSAGES.city.empty;
    } else if (!isValidAddressComponent) {
      return ADDRESS_ERROR_MESSAGES.city.invalid;
    }
    return null;
  };

  const validateState = () => {
    if (!formData.state) {
      return ADDRESS_ERROR_MESSAGES.state.empty;
    }
    return null;
  };

  const validateZipCode = () => {
    if (!formData.zip_code) {
      return ADDRESS_ERROR_MESSAGES.zip_code.empty;
    } else if (formData.zip_code.length !== 5) {
      return ADDRESS_ERROR_MESSAGES.zip_code.invalid;
    }
    return null;
  };

  const buttonOnSubmit = () => {
    const customValidators = {
      street: validateStreet,
      city: validateCity,
      state: validateState,
      zip_code: validateZipCode,
    };

    const validationErrors = {};
    let isValid = true;

    Object.keys(customValidators).forEach(field => {
      const fieldError = customValidators[field]();
      if (fieldError) {
        isValid = false;
        validationErrors[field] = fieldError;
      }
    });

    if (isValid) {
      onSubmit();
    } else {
      Object.keys(validationErrors).forEach(field => {
        form?.setErrors(field, validationErrors[field], true);
      });
    }
  };

  const streetField = registerField('street');
  const unitField = registerField('unit');
  const cityField = registerField('city');
  const stateField = registerField('state');
  const zipField = registerField('zip_code');

  return (
    <div className="Property PropertyAddress" data-testid="inquiry_property_page">
      <Helmet title={PAGE_TITLES[PROGRESSIVE_STEP_NAMES.PROPERTY]} />
      <ProgressiveUITemplate>
        <ContentPanel>
          <h1>Is your property eligible for a Hometap Investment?</h1>
          <p className="PropertyBodyText InquiryBodyText">
            You can prequalify for a Hometap investment in{' '}
            <span className="InquiryEmphasisBold">less than two minutes.</span> First, we need your address.
          </p>
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid xs={12} sm={!isScreenSize('md') ? 8 : 12}>
              <MuiTextInput
                name="street"
                label="Street
                address"
                maxLength={50}
                required
                width="100%"
                ref={ref}
                data-testid="property-address-input"
                {...streetField}
                validator={() => touchedFields.street && validateStreet()}
                onChange={(...args) => {
                  streetField.onChange(...args);
                  setTouchedFields({ ...touchedFields, street: true });
                }}
                showRequiredAsterisk={false}
              />
            </Grid>
            <Grid xs={12} sm={!isScreenSize('md') ? 4 : 12}>
              <MuiTextInput
                name="unit"
                label="Unit (optional)"
                maxLength={50}
                width="100%"
                data-testid="property-unit-input"
                {...unitField}
              />
            </Grid>
            <Grid xs={12} sm={12}>
              <MuiTextInput
                name="city"
                label="City"
                maxLength={50}
                required
                width="100%"
                data-testid="property-city-input"
                {...cityField}
                validator={() => touchedFields.city && validateCity()}
                onChange={(...args) => {
                  cityField.onChange(...args);
                  setTouchedFields({ ...touchedFields, city: true });
                }}
                showRequiredAsterisk={false}
              />
            </Grid>
            <Grid xs={6} sm={!isScreenSize('md') ? 8 : 6} className="InquiryAddressModalState">
              <MuiSelect
                name="state"
                label="State"
                required
                options={statesForDropdown}
                className="property-state-input"
                {...stateField}
                validator={() => touchedFields.state && validateState()}
                onChange={(...args) => {
                  stateField.onChange(...args);
                  setTouchedFields({ ...touchedFields, state: true });
                }}
                width="100%"
                showRequiredAsterisk={false}
              />
            </Grid>
            <Grid xs={6} sm={!isScreenSize('md') ? 4 : 6} className="InquiryAddressModalZip">
              <MuiTextInput
                name="zip_code"
                label="ZIP code"
                type="number"
                inputMode="number"
                maxLength={5}
                required
                mask="00000"
                validator={() => touchedFields.zip_code && validateZipCode()}
                data-testid="property-zip-input"
                {...zipField}
                onChange={(...args) => {
                  zipField.onChange(...args);
                  setTouchedFields({ ...touchedFields, zip_code: true });
                }}
                width="100%"
                showRequiredAsterisk={false}
              />
            </Grid>
          </Grid>
          <CallToAction>
            <Button id={CONTINUE_BUTTON_ID} onClick={buttonOnSubmit} loading={loading}>
              Continue
            </Button>
          </CallToAction>
        </ContentPanel>
        <ImageContentPanel imageSource={houseImg} imgAlt="car parked in driveway" includeTrustPilot={true} />
      </ProgressiveUITemplate>
    </div>
  );
};

export default InquiryPropertyAddressVariation;
