import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Grid, SelectionSet, useWindowSize, Button } from '@hometap/htco-components';
import { useEquityScenarioContext } from 'apps/dashboard/hooks';
import { SELECTION_OPTIONS, MAX_LIENS, PROFILE_STAGES } from 'apps/dashboard/constants/buildYourProfile';
import buildProfileLandingImg from 'images/dashboard/build-profile-landing.png';
import PercentageBadge from '../dashboard-shared/PercentageBadge';
import { LOCAL_STORAGE_LATER_KEY } from 'apps/dashboard/constants';
import { useDelayHomeEquityProfile } from 'apps/dashboard/hooks';
import { browserTrack } from 'utils/segment';
import { getVersion } from 'utils/env';
import { getNewDashboardUrl, HOMEOWNER_DASHBOARD } from 'utils/links';
import './BuildProfileLandingPage.scss';
import { useCurrentHome, useCurrentHomeMutation } from 'hooks/useCurrentHome';

const BuildProfileLandingPage = ({ onNext, selections }) => {
  const navigate = useNavigate();
  const { isScreenSize } = useWindowSize();
  const { home } = useCurrentHome();
  const homeMutation = useCurrentHomeMutation();
  const { currentScenario, getEquityScenarioLiensAsync, deleteLien } = useEquityScenarioContext();

  const { handleSetupProfileLater, removeDelayLocalStorageKey } = useDelayHomeEquityProfile();

  const equityScenarioLiens = getEquityScenarioLiensAsync?.results;

  const isScreenXs = isScreenSize('xs');

  // Logic to send user directly to empty state dashboard or render the build profile page
  useEffect(() => {
    const setupProfileLaterIds = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LATER_KEY) || '[]');
    const canSetupProfileLater = !setupProfileLaterIds.includes(home?.id);
    if (canSetupProfileLater) {
      browserTrack.skipBuildProfile({
        version: getVersion(),
      });
      handleSetupProfileLater();
    }
    // eslint-disable-next-line
  }, [home?.id]);

  const onNoDebts = async () => {
    removeDelayLocalStorageKey(home?.id);
    equityScenarioLiens?.forEach(async lien => {
      await deleteLien(currentScenario?.id, lien.id);
    });
    await Promise.all([
      await homeMutation.mutateAsync({ hed_debts_confirmed: true }),
      getEquityScenarioLiensAsync.execute(home?.id, currentScenario?.id),
    ]);
    return navigate(getNewDashboardUrl(HOMEOWNER_DASHBOARD.HOME_EQUITY));
  };

  const handleSelect = async value => {
    switch (value) {
      case SELECTION_OPTIONS.USE_EXISTING:
        return onNext(PROFILE_STAGES.DEBT_SELECTION_STEP);
      case SELECTION_OPTIONS.NO_DEBTS:
        return await onNoDebts();
      default:
        return null;
    }
  };

  /*
    - Disable the mortgage loan option one already exists on the equity scenario
    - Disable the button to manually add debts if the equity scenario contains the max number of liens (one of each type)
   */
  const options = selections?.map(selection => {
    const hasAllLiens = equityScenarioLiens?.length === MAX_LIENS;
    const shouldDisableManualDebt = selection.value === SELECTION_OPTIONS.USE_EXISTING && hasAllLiens;

    if (shouldDisableManualDebt) {
      return { ...selection, disabled: true };
    }
    return selection;
  });

  return (
    <>
      <Grid className="DashboardBuildProfileLanding" container data-testid="build-profile-landing">
        <Grid sm={12} md={6}>
          <div className="DashboardBuildProfileLeftPanel">
            <h1>Build your home profile</h1>
            {isScreenXs && <PercentageBadge />}
            <div className="DashboardBuildProfileSubtitle">
              By capturing all debts attached to your property, we’ll get the most complete picture of your home equity
              possible.
            </div>

            <SelectionSet
              align="left"
              error={null}
              labelWidth="100%"
              name="fieldName"
              onChange={handleSelect}
              options={options}
            />
            <Button
              className="DashboardBuildProfileLaterBtn"
              size="normal"
              theme="link"
              onClick={handleSetupProfileLater}
            >
              I’ll do this later
            </Button>
          </div>
        </Grid>
        <Grid sm={12} md={6} display="flex" justifyContent="center" alignItems="center">
          <div className="DashboardBuildProfileRightPanel">
            <img className="DashboardBuildProfileLandingImage" src={buildProfileLandingImg} alt="home-equity" />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default BuildProfileLandingPage;
