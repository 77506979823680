import { STAGES } from 'apps/dashboard/constants/trackConstants';
import { isTrackExpiredOrClosed } from 'utils/tracksUtils';
import { isTrackFunded } from 'apps/track-details/utils/config';
import { convertDateStringToHumanReadable } from 'utils/date';

export const isCLosedOrSettled = track => {
  return isTrackExpiredOrClosed(track) || track.current_stage === STAGES.SETTLED;
};

export const getProgressTrackerStage = track => {
  const { current_stage } = track;
  switch (current_stage) {
    case STAGES.APP_READY:
    case STAGES.APP_IN_PROGRESS:
    case STAGES.APP_IN_REVIEW:
      if (track?.initial_application_reviewed) {
        return 2;
      }
      return 1;
    case STAGES.APP_APPROVED:
    case STAGES.OFFER_PREPARED:
      return 2;
    case STAGES.OFFER_READY:
    case STAGES.OFFER_DECLINED:
      return 3;
    case STAGES.CONDITIONS_IN_REVIEW:
    case STAGES.CLOSING_IN_PROGRESS:
      return 4;
    case STAGES.INVESTED:
    case STAGES.SETTLED:
    case STAGES.CLOSED_LOST:
    default:
      return 0;
  }
};

export const getInvestmentCardLinks = (track, investment) => {
  const { current_stage, id, active_investment_id, active_offer_rundown_id, active_application_id } = track;
  switch (current_stage) {
    case STAGES.APP_READY:
    case STAGES.APP_IN_PROGRESS:
    case STAGES.APP_IN_REVIEW:
    case STAGES.APP_APPROVED:
    case STAGES.OFFER_PREPARED:
      // TODO: Add conditional estimate link
      const applicationLinks = [{ label: 'Documents', link: `/track/${id}/documents` }];
      const inApplicationStage = current_stage === STAGES.APP_READY || current_stage === STAGES.APP_IN_PROGRESS;
      if (inApplicationStage && active_application_id) {
        applicationLinks.push({ label: 'Application', link: `/track/${id}/application` });
      }
      return applicationLinks;
    case STAGES.OFFER_READY:
    case STAGES.OFFER_DECLINED:
    case STAGES.CONDITIONS_IN_REVIEW:
    case STAGES.CLOSING_IN_PROGRESS:
      const offerLinks = [{ label: 'Documents', link: `/track/${id}/documents` }];
      if (active_offer_rundown_id) {
        offerLinks.push({ label: 'Offer', link: `/offer/${active_offer_rundown_id}` });
      }
      return offerLinks;
    case STAGES.INVESTED:
      const shouldShowSettlementCalculator =
        investment && investment.effective_date && investment.beginning_home_value && investment.investment_amount;
      const investedLinks = [{ label: 'Statements & Documents', link: `/track/${id}/documents` }];
      if (shouldShowSettlementCalculator) {
        investedLinks.push({
          label: 'Settlement calculator',
          link: `/investments/${active_investment_id}/settlement-calculator`,
        });
      }
      return investedLinks;
    case STAGES.SETTLED:
    case STAGES.CLOSED_LOST:
    default:
      return [];
  }
};

export const mapUIStageBadgeConfig = track => {
  const { current_stage, current_stage_display } = track;
  if (!isTrackFunded(track) && !isTrackExpiredOrClosed(track)) {
    return {
      label: 'In Progress',
      theme: 'neutralLight50',
    };
  }
  if (current_stage === STAGES.INVESTED) {
    return {
      label: STAGES.INVESTED,
      theme: 'secondaryGreyOutlined',
    };
  }
  return {
    label: current_stage_display,
    theme: 'neutralDark50',
  };
};

const FUNDED_ON_LABEL = 'Funded on:';
const SETTLED_ON_LABEL = 'Settled on:';
const STARTED_ON_LABEL = 'Started on:';

export const getTimeStatus = track => {
  const { current_stage, effective_date, settlement_date, inquiry_created_date } = track;
  const status = (label, date) => {
    return { label, date: date ? convertDateStringToHumanReadable(date, true) : 'Invalid date' };
  };
  if (current_stage === STAGES.INVESTED) {
    return status(FUNDED_ON_LABEL, effective_date);
  } else if (current_stage === STAGES.SETTLED) {
    return status(SETTLED_ON_LABEL, settlement_date);
  }
  return status(STARTED_ON_LABEL, inquiry_created_date);
};

const INVESTED_AMOUNT_LABEL = 'Investment amount:';
const INVESTMENT_ESTIMATE_LABEL = 'Estimated max. Investment amount:';
const OFFER_AMOUNT_LABEL = 'Offer amount:';

export const getInvestmentAmountLabel = stage => {
  if (!stage) {
    return INVESTMENT_ESTIMATE_LABEL;
  }
  const STAGE_TO_AMOUNT_LABEL_MAPPING = {
    [STAGES.APP_READY]: INVESTMENT_ESTIMATE_LABEL,
    [STAGES.APP_IN_PROGRESS]: INVESTMENT_ESTIMATE_LABEL,
    [STAGES.APP_IN_REVIEW]: INVESTMENT_ESTIMATE_LABEL,
    [STAGES.APP_APPROVED]: INVESTMENT_ESTIMATE_LABEL,
    [STAGES.OFFER_PREPARED]: INVESTMENT_ESTIMATE_LABEL,
    [STAGES.OFFER_READY]: OFFER_AMOUNT_LABEL,
    [STAGES.CONDITIONS_IN_REVIEW]: INVESTED_AMOUNT_LABEL,
    [STAGES.CLOSING_IN_PROGRESS]: INVESTED_AMOUNT_LABEL,
    [STAGES.INVESTED]: INVESTED_AMOUNT_LABEL,
    [STAGES.SETTLED]: INVESTED_AMOUNT_LABEL,
    [STAGES.CLOSED_LOST]: INVESTMENT_ESTIMATE_LABEL,
    [STAGES.OFFER_DECLINED]: OFFER_AMOUNT_LABEL,
  };

  return STAGE_TO_AMOUNT_LABEL_MAPPING[stage];
};
