import React, { useEffect, useState } from 'react';
import { Button, Icon, MuiTextInput, SelectionSet, Tooltip, useAsync } from '@hometap/htco-components';
import { CallToAction, ContentPanel, ProgressiveUITemplate } from './ProgressiveUITemplate';
import {
  CONTINUE_BUTTON_ID,
  PROGRESSIVE_FORM_FIELDS as FIELDS,
  PRIMARY_USE_CASE_OPTIONS,
  scrollIntoViewByElementId,
} from '../constants/progressiveInquiryConstants';
import { patchInquiry } from '../../inquiry/data/inquiryRequests';
import hangingPlant1 from 'images/inquiry/hanging_plant_1.png';
import hangingPlant2 from 'images/inquiry/hanging_plant_2.png';
import './InquiryPropertyUseCase.scss';
import useCTAChangedNotification from 'hooks/useCTAChangedNotification';

const USE_CASE_OTHER = 'use_case_other';

const InquiryPropertyUseCase = ({ inquiryId, inquiryUseCases = [], form, step, onNext }) => {
  const { formData, handleSubmit, registerField } = form;
  const { primary_use_case, primary_use_case_choice_details } = formData;

  const { results, loading, execute: updateInquiry } = useAsync(patchInquiry);
  const [otherSelected, setOtherSelected] = useState(false);

  useEffect(() => {
    if (results) {
      onNext(step + 1);
    }
  }, [results, onNext, step]);

  const onSubmit = () => {
    if (primary_use_case === USE_CASE_OTHER && !otherSelected) {
      setOtherSelected(true);
    } else {
      const updateApi = handleSubmit(() =>
        updateInquiry(inquiryId, { primary_use_case, primary_use_case_choice_details }),
      );
      updateApi();
    }
  };

  const canContinueToTheNextPage = () => {
    if (primary_use_case === USE_CASE_OTHER && otherSelected) {
      return !!(primary_use_case && primary_use_case_choice_details);
    }
    return !!primary_use_case;
  };

  canContinueToTheNextPage() && scrollIntoViewByElementId(CONTINUE_BUTTON_ID);
  const ctaText = 'Continue';
  useCTAChangedNotification(ctaText, canContinueToTheNextPage());

  const buildUseCaseOptions = inquiryUseCases.reduce((selectionItems, useCase) => {
    const { label, value } = useCase;
    const { icon, description } = PRIMARY_USE_CASE_OPTIONS.find(option => option.value === value) || {};

    // We need at least an icon defined in order to render a option from the backend
    if (icon) {
      selectionItems.push({ label, icon, value, description });
    }
    return selectionItems;
  }, []);

  return (
    <>
      <div className="PropertyUseCase" data-testid="inquiry_property_use_case_page">
        <ProgressiveUITemplate>
          <ContentPanel gridSize={12}>
            <div className="PropertyUseCaseHeaderContainer">
              <h1 className="PropertyUseCaseHeader">
                What’s the primary reason you’re interested in a Hometap investment?{' '}
                <Tooltip
                  content="Understanding your needs as a homeowner allows us to provide the best product and experience possible. This information will help us customize your content, and won’t affect your eligibility for a Hometap Investment."
                  action="click"
                >
                  <Icon color="#697891" className="PropertyUseCaseHeaderIcon" name="icon-question-circle" size="sm" />
                </Tooltip>
              </h1>
              <div className="PlantImageContainer">
                <img className="PlantLeft" src={hangingPlant1} alt="large hanging plant" />
                <img className="PlantRight" src={hangingPlant2} alt="small hanging plant" />
              </div>
            </div>
            <div className="InquirySelectionSet">
              {!otherSelected && (
                <SelectionSet
                  name="propertyUseCaseOptions"
                  labelWidth="50%"
                  labelGrow={false}
                  className="InquiryPropertyUseCaseSelection"
                  options={buildUseCaseOptions}
                  {...registerField(FIELDS.PRIMARY_USE_CASE)}
                />
              )}
            </div>
            {otherSelected && (
              <div className="PropertyUseCaseOther">
                <MuiTextInput
                  label="Other (You tell us!)"
                  name="other"
                  width="570px"
                  helperText="Let us know how you’d like to use a Hometap investment"
                  {...registerField(FIELDS.PRIMARY_USE_CASE_CHOICE_DETAILS)}
                />
              </div>
            )}
            <CallToAction>
              <Button
                id={CONTINUE_BUTTON_ID}
                disabled={!canContinueToTheNextPage()}
                className="PropertyUseCaseCTA"
                onClick={onSubmit}
                loading={loading}
              >
                {ctaText}
              </Button>
            </CallToAction>
          </ContentPanel>
        </ProgressiveUITemplate>
      </div>
    </>
  );
};

export default InquiryPropertyUseCase;
