import cx from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Button, Loader } from '@hometap/htco-components';
import Footer from 'components/Footer/Footer';
import FEATURED_OFFERS from './featuredOffers';
import { useCurrentHome } from 'hooks/useCurrentHome';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';

import PrimaryLayoutGrid from 'components/PrimaryLayoutGrid/PrimaryLayoutGrid';

const FeaturedOffer = ({ offer }) => {
  const { id, image, title, body, bodyAlt, logo, logoAlt, ctaText, ctaLink, disclaimer } = offer;

  return (
    <div className="group m-auto mt-12 flex flex-col gap-4 border-0 border-b border-solid border-neutral-light-100 pb-16 pt-22 even:justify-end first-of-type:pt-0 last-of-type:border-b-0 sm:mt-0 sm:max-w-[90%] md:gap-6">
      <div className="flex flex-col-reverse justify-between sm:flex-row sm:group-even:flex-row-reverse">
        <div className="my-12 flex w-full max-w-full xs:max-sm:min-w-full sm:w-1/2 sm:max-md:min-w-[434px]">
          <img className="m-auto block w-[90%] max-w-[488px]" src={image} alt={bodyAlt} />
        </div>
        <div>
          <div className="max-w-[560px] md:max-w-[616px]">
            <img className={cx('max-w-[170px]', { 'max-w-[117px]': id === 'simplisafe' })} src={logo} alt={logoAlt} />
            <h4 className="mb-4 mt-6">{title}</h4>

            <ul className="ml-11 list-disc">
              {body?.map(item => {
                return (
                  <li
                    className="mt-2 text-sm leading-6 text-neutral-dark-100 marker:text-green-medium sm:text-base"
                    key={item}
                  >
                    {item}
                  </li>
                );
              })}
            </ul>

            <Button className="my-6 w-full xs:w-auto" size="small" href={ctaLink} target="_blank">
              {ctaText}
            </Button>
            <div className="text-xs text-neutral-dark-50">{disclaimer}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeaturedOffersController = () => {
  const flags = useFlags();

  const { home, loading } = useCurrentHome();

  if (loading || !home) {
    return (
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <Loader type="dot-pulse" />
      </div>
    );
  }

  const configContext = { flags, home };

  const renderedOffers = FEATURED_OFFERS.filter(offer => {
    return offer.shouldShowOffer ? offer.shouldShowOffer(configContext) : true;
  });

  return (
    <ScrollToTop>
      <div className="to-transparent bg-gradient-to-t from-blue-15 via-blue-15">
        <PrimaryLayoutGrid>
          <div className="col-span-full">
            <div className="mb-2 text-sm uppercase tracking-wider text-neutral-dark-100">
              Offers for you from Hometap’s
            </div>
            <h2>
              <span className="text-green-medium">Best-in-class </span>partners
            </h2>
            <div className="mb-[86px] mt-2 max-w-[560px] text-sm leading-6 xs:text-base sm:text-lg sm:leading-8 md:max-w-[744px]">
              <strong>As a homeowner, you already have plenty to think about.</strong> That’s why we’ve done the work to
              find and select the best partners possible to help you get the most out of homeownership — industry
              leaders who put their customers first.
            </div>
            <div className="mb-8 sm:mb-26">
              {renderedOffers.map(offer => {
                const configuredCtaLink =
                  typeof offer.ctaLink === 'function' ? offer.ctaLink(configContext) : offer.ctaLink;
                const componentOverride = offer.componentOverride && offer.componentOverride(configContext);

                if (componentOverride) {
                  return componentOverride;
                }

                return <FeaturedOffer key={offer.id} offer={{ ...offer, ctaLink: configuredCtaLink }} />;
              })}
            </div>
            <Footer theme="light" />
          </div>
        </PrimaryLayoutGrid>
      </div>
    </ScrollToTop>
  );
};

export default FeaturedOffersController;
