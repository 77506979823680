import { STAGES } from 'apps/dashboard/constants';

export const UI_STAGE = {
  Apply: { key: 'APPLY', label: 'Apply' },
  Review: { key: 'REVIEW', label: 'Review' },
  Accept: { key: 'ACCEPT', label: 'Accept' },
  Sign: { key: 'SIGN', label: 'Sign' },
  Invested: { key: 'INVESTED', label: 'Invested' },
  Settled: { key: 'SETTLED', label: 'Settled' },
  Closed: { key: 'CLOSED', label: 'Closed' },
};

export const TRACK_STAGE_TO_UI_STAGE = {
  [STAGES.APP_READY]: UI_STAGE.Apply,
  [STAGES.APP_IN_PROGRESS]: UI_STAGE.Apply,
  [STAGES.APP_IN_REVIEW]: UI_STAGE.Review,
  [STAGES.APP_APPROVED]: UI_STAGE.Review,
  [STAGES.OFFER_PREPARED]: UI_STAGE.Review,
  [STAGES.OFFER_READY]: UI_STAGE.Accept,
  [STAGES.OFFER_DECLINED]: UI_STAGE.Accept,
  [STAGES.CONDITIONS_IN_REVIEW]: UI_STAGE.Sign,
  [STAGES.CLOSING_IN_PROGRESS]: UI_STAGE.Sign,
  [STAGES.INVESTED]: UI_STAGE.Invested,
  [STAGES.SETTLED]: UI_STAGE.Settled,
  [STAGES.CLOSED_LOST]: UI_STAGE.Closed,
};

export const TODO_TIME_TO_COMPLETE = {
  ASYNC: '2 minutes',
};

export const PLACEHOLDER_TODO = {
  ESTIMATE: {
    title: 'View your Estimate.',
    subtitle:
      'Talk through your Estimate on a call with your Investment Manager. Get answers to questions about share price, Investment amount, and more.',
    timeToComplete: '10 minutes',
    isComplete: false,
  },
  OFFER: {
    title: 'View your Investment Offer.',
    subtitle:
      'Please review your official Investment Offer and click the "accept" or "decline" button on the top of the screen to proceed. ',
    timeToComplete: '10 minutes',
    isComplete: false,
  },
  // generic placeholder for unknown future todos
  DOC_UPLOAD: {
    title: 'Upload additional documents.',
    subtitle:
      'If you have additional liens on the property, we might request a signed copy of any mortgage modification agreements to understand the terms.',
    timeToComplete: TODO_TIME_TO_COMPLETE.ASYNC,
  },
  GOV_ID_UPLOAD: {
    title: 'Upload government IDs for each applicant.',
    subtitle:
      'A government-issued identification document, such as a passport or driver’s license, commonly used for travel, employment, or government service access.',
    timeToComplete: TODO_TIME_TO_COMPLETE.ASYNC,
  },
  MORTGAGE_STATEMENT_UPLOAD: {
    title: 'Upload your most recent mortgage statement(s).',
    subtitle: 'This should be the most recent statement you’ve received from your lender.',
    timeToComplete: TODO_TIME_TO_COMPLETE.ASYNC,
  },
  HOMEOWNERS_INSURANCE_DECLARATION_UPLOAD: {
    title: 'Upload your homeowners insurance declaration page.',
    subtitle:
      'A document that summarizes a homeowners insurance policy. It includes the property address, policy period, coverage limits, deductibles, and endorsements.',
    timeToComplete: TODO_TIME_TO_COMPLETE.ASYNC,
  },
};

export const RESOURCE_TYPE = {
  OFFER: { key: 'offer', icon: 'money-check' },
  RUNDOWN: { key: 'rundown', icon: 'notes-cash' },
  DOCUMENTS_PAGE_LINK: { key: 'documentsPageLink' },
  IDD_DOCUMENT: { key: 'iddDocument', icon: 'investment-disclosure' },
};
