import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, useWindowSize } from '@hometap/htco-components';
import AccountDropdown from '../../AccountDropdown/AccountDropdown';

const SecondaryLayout = ({ children, prevUrl, title, allowAccountDropdown = false }) => {
  const { screenWidth } = useWindowSize();
  const { isScreenSize } = useWindowSize();
  const isTablet = isScreenSize('sm');

  return (
    <aside className="h-screen">
      <div className="fixed z-50 flex h-18 w-screen min-w-0 flex-row justify-between border-0 border-b border-solid border-neutral-light-100 bg-white md:h-22">
        <div className="flex flex-row">
          <Link
            className="htco- group z-50 flex items-center pl-6 pr-6 focus-visible:outline-none sm:pl-8 md:pl-18 lg:pl-27"
            to={prevUrl}
            data-testid="back-button"
          >
            <div className="flex flex-row rounded outline-1 outline-offset-2 outline-blue-100 group-focus-visible:outline">
              <Icon
                name="chevron2-left"
                className="h-6 pt-[3px] !text-[24px] text-neutral-dark-75 group-hover:text-blue-100 group-active:text-blue-medium-100"
              />
              {(screenWidth > 1280 || !title) && (
                <p className="m-0 pl-2 pr-1 font-bold text-neutral-dark-75 group-hover:text-blue-100 group-active:text-blue-medium-100">
                  Back
                </p>
              )}
            </div>
          </Link>
          {title && (
            <div className="flex items-center overflow-hidden pr-6 sm:pr-8 md:pr-18 lg:pr-27">
              <div
                data-testid="divider"
                className="mr-6 h-full border-0 border-r border-solid border-neutral-light-100"
              />
              <p data-testid="title" className="truncate font-bold">
                {title}
              </p>
            </div>
          )}
        </div>
        {allowAccountDropdown && !isTablet && (
          <div className="content-center pr-27">
            <AccountDropdown />
          </div>
        )}
      </div>
      <div className="pt-18 md:pt-22">{children}</div>
    </aside>
  );
};

export default SecondaryLayout;
