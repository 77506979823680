import React from 'react';
import { Badge, Button, useWindowSize } from '@hometap/htco-components';
import { Link } from 'react-router-dom-v5-compat';
import { currency } from 'utils/numbers';
import { config, isTrackFunded } from 'apps/track-details/utils/config';
import {
  getInvestmentCardLinks,
  getInvestmentAmountLabel,
  getProgressTrackerStage,
  mapUIStageBadgeConfig,
  getTimeStatus,
  isCLosedOrSettled,
} from 'apps/dashboard/utils/investmentUtils';
import useHedInvestmentContext from 'apps/dashboard/hooks/useHedInvestmentContext';
import TrackDetailsProgressStepper from 'apps/track-details/components/TrackDetailsProgressStepper/TrackDetailsProgressStepper';
// import Avatar from './Avatar';

const InvestmentCard = ({ track }) => {
  const { id, friendly_id, current_stage, investment_amount, membership } = track;
  const { isScreenSize } = useWindowSize();
  const { investment } = useHedInvestmentContext();

  const allUIProgressStepperStages = Object.keys(config).filter(key => !config[key].showWithoutOtherStages);
  const links = getInvestmentCardLinks(track, investment);
  const { label: timeLabel, date } = getTimeStatus(track);
  const { label: stageLabel, theme } = mapUIStageBadgeConfig(track);
  const isPrimaryMember = membership?.role === 'primary';
  const showLinksAndAvatarSection = isPrimaryMember && !isCLosedOrSettled(track);
  const progressTrackerStage = getProgressTrackerStage(track);
  const ctaCopy = isTrackFunded(track) ? 'View details' : 'Check status';
  const ctaLink = isPrimaryMember ? `/track/${id}` : `/track/${id}/co-applicant`;

  return (
    <div className="gap-6 rounded-xl bg-white p-6 shadow-[0_2px_14px_rgba(32,53,104,0.08)]">
      <div className="mb-6">
        {progressTrackerStage ? (
          <TrackDetailsProgressStepper
            steps={allUIProgressStepperStages}
            activeIndex={progressTrackerStage - 1}
            isCardView={true}
          />
        ) : null}
      </div>
      <div className="flex flex-col justify-between xs:flex-row">
        <div>
          <div className="mb-2 mt-0 flex flex-row flex-wrap-reverse items-center sm:mb-[50px] sm:mt-[13px]">
            <div className="mr-4 min-w-[100%] flex-1 pt-0 text-base text-neutral-dark-75 sm:min-w-[0] sm:pt-[2px] sm:text-lg">
              <span className="pr-2">Investment ID:</span>
              <span className="font-bold text-neutral-dark-100">{friendly_id}</span>
            </div>
            <Badge className="static mb-4 sm:mb-0" label={stageLabel} theme={theme} />
          </div>
          <div className="text-sm text-neutral-dark-75 sm:text-base">
            <span className="pr-2">{timeLabel}</span>
            <span className="font-bold text-neutral-dark-100">{date}</span>
          </div>
          <div className="mt-2 text-sm text-neutral-dark-75 xs:mb-6 sm:text-base">
            <span className="pr-2">{getInvestmentAmountLabel(current_stage)}</span>
            <span className="font-bold text-neutral-dark-100">
              {investment_amount ? currency(investment_amount) : 'More info needed'}
            </span>
          </div>
        </div>
        <div className="mb-6 mt-4 w-full xs:mt-0 xs:mt-0 xs:flex xs:w-auto">
          <Button className="[&.htco-Button]:m-0 [&.htco-Button]:w-full" href={ctaLink}>
            {ctaCopy}
          </Button>
        </div>
      </div>
      {showLinksAndAvatarSection && (
        <div className="border-1 mt-4 flex flex-col items-start justify-between rounded-lg border-neutral-light-75 bg-neutral-light-50 p-4 xs:flex-row xs:items-center sm:px-6 sm:py-[27px]">
          <div className="align-center flex flex-col justify-center sm:flex-row">
            <div className="mb-2 mr-3 pt-[2px] text-sm text-neutral-dark-75 sm:mb-0 sm:text-base">View:</div>
            <div className="flex flex-col pr-4 xs:flex-row">
              {links.map(({ label, link }, index) => (
                <div key={label} className="mb-2 sm:mb-0">
                  <Link to={link}>
                    <Button
                      theme="link"
                      className="[&.htco-isLink]:ml-0 [&.htco-isLink]:text-sm sm:[&.htco-isLink]:text-base"
                    >
                      {label}
                    </Button>
                  </Link>
                  {!isScreenSize('xs') && index !== links.length - 1 && (
                    <span className="mr-2 border-0 border-r border-solid border-neutral-light-100 pr-2" />
                  )}
                </div>
              ))}
            </div>
          </div>
          {/* AVATAR SECTION - TO BE IMPLEMENTED */}
          {/* <div className="mt-4 flex w-full flex-col items-start xs:mt-0 xs:w-auto sm:flex-row sm:items-center">
          <span className="mb-2 mr-4 text-sm text-neutral-dark-75 sm:mb-0 sm:text-base">Applicants:</span>
          <Avatar initials={usersInitials?.[0]} />
        </div> */}
        </div>
      )}
    </div>
  );
};

export default InvestmentCard;
