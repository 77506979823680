import {
  PREQUAL_OUTCOME_CODES,
  PREQUAL_TYPES,
  RISK_BAND_TYPES,
  FICO_SCORE_TYPES,
  FAILED_PREQUAL_REDIRECT_MAP,
} from '../constants/APIConstants';

const { STATE, RISK, INVESTMENT_AMOUNT } = PREQUAL_TYPES;

// fuction to parse risk outcome details
export const parseRiskPrequalOutcomeDetails = outcomeString => {
  const risk_band = outcomeString.match(/risk band: (.*?),/);
  const fico_score = outcomeString.match(/fico score: (.*?)\)/);

  return { risk_band: risk_band ? risk_band[1] : null, fico_score: fico_score ? fico_score[1] : null };
};

export const showSSNForm = riskPrequal => {
  const riskOutcome = parseRiskPrequalOutcomeDetails(riskPrequal.outcome_details);
  return (
    riskOutcome.risk_band === RISK_BAND_TYPES.NO_DATA_NO_SSN ||
    riskOutcome.fico_score === FICO_SCORE_TYPES.NO_DATA_NO_SSN
  );
};

export const getFailedRiskPrequalRedirect = (riskPrequal = {}) => {
  const riskOutcome = parseRiskPrequalOutcomeDetails(riskPrequal.outcome_details);
  if (riskOutcome.risk_band === RISK_BAND_TYPES.FAIL) {
    return FAILED_PREQUAL_REDIRECT_MAP[PREQUAL_TYPES.RISK];
  }
  return FAILED_PREQUAL_REDIRECT_MAP.FICO_SCORE_AA;
};

export const getFailedPrequalParam = prequal => {
  if (prequal.kind === STATE) {
    return FAILED_PREQUAL_REDIRECT_MAP[prequal.kind][prequal.outcome_details];
  }
  if (prequal.kind === RISK) {
    return getFailedRiskPrequalRedirect(prequal);
  }
  return FAILED_PREQUAL_REDIRECT_MAP[prequal.kind];
};

// get the prequalification that made the InvestmentAmountPrequalification fail
export const getInvestmentAmountFailureKind = (investmentAmountDetails = '') => {
  return investmentAmountDetails.split(':')?.[0];
};

export const parsePrequalificationsToMap = (prequalifications = []) => {
  const data = {
    outcome: PREQUAL_OUTCOME_CODES.PASS,
    failedParam: null,
    maxAmount: null,
    showSSN: false,
    prequalifications: {},
    investmentFailedOrUndeterminedPrequal: null, // If InvestmentAmountPrequalification failed, this is the prequal responsible for failure
    ficoScore: null,
    riskFailFactors: [],
    experianDataPulledAt: null,
  };

  prequalifications.forEach(prequal => {
    if (prequal.status === 'active') {
      // Do not run if a prequal has failed
      if (data.outcome === PREQUAL_OUTCOME_CODES.PASS || data.outcome === PREQUAL_OUTCOME_CODES.UNDETERMINED) {
        if (prequal.outcome_code === PREQUAL_OUTCOME_CODES.FAIL) {
          data.outcome = PREQUAL_OUTCOME_CODES.FAIL;
          data.failedParam = getFailedPrequalParam(prequal);
          if (prequal.kind === INVESTMENT_AMOUNT) {
            data.investmentFailedOrUndeterminedPrequal = getInvestmentAmountFailureKind(prequal?.outcome_details);
          }
        } else if (prequal.outcome_code === PREQUAL_OUTCOME_CODES.UNDETERMINED) {
          data.outcome = PREQUAL_OUTCOME_CODES.UNDETERMINED;
          if (prequal.kind === INVESTMENT_AMOUNT) {
            data.investmentFailedOrUndeterminedPrequal = getInvestmentAmountFailureKind(prequal?.outcome_details);
          }
        }
      }

      if (prequal.kind === RISK) {
        if (showSSNForm(prequal)) {
          data.showSSN = true;
        }
        if (prequal?.additional_details_dict) {
          data.ficoScore = prequal.additional_details_dict?.fico_score;
          data.riskFailFactors = prequal.additional_details_dict?.risk_fail_factors;
          data.experianDataPulledAt = prequal.additional_details_dict?.experian_data_pulled_at;
        }
      }

      if (prequal.kind === PREQUAL_TYPES.INVESTMENT_AMOUNT && prequal.outcome_code === PREQUAL_OUTCOME_CODES.PASS) {
        data.maxAmount = prequal.additional_details_dict?.max_amount;
      }

      data.prequalifications[prequal.kind] = {
        outcome_code: prequal.outcome_code,
        outcome_details: prequal.outcome_details,
        additional_details: prequal.additional_details_dict,
      };
    }
  });

  return data;
};
