import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { ICON_SCRIPT } from '@hometap/htco-components';
import AppRouter from './AppRouter';
import segment from 'utils/segment';
import sentry from 'utils/sentry';
import { ErrorBoundary } from '@sentry/react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { CurrentUserProvider } from 'hooks/useCurrentUser';
import { CookiesProvider, useCookies } from 'react-cookie';
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk';
import { SystemStatusProvider } from 'hooks/useSystemStatus';
import IdleWarningModal from 'components/modals/IdleWarningModal/IdleWarningModal';
import useCurrentUser from 'hooks/useCurrentUser';
import 'utils/window';
import { UserTracksProvider } from 'hooks/useUserTracksContext';
import env from 'utils/env';
import { NotificationBannerProvider } from 'hooks/useNotificationBannerContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CurrentHomeProvider } from 'hooks/useCurrentHome';
import { browserWindow } from 'apps/htdc/utils/window';
import { getCookieDomain } from 'utils/cookies';

const queryClient = new QueryClient();

// This theme is for MUI components used in the component library, particularly our Grid component
export const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1728,
    },
  },
  spacing: 8, // spacing={2}  -> 8 * 2 = '16px'
});

const keyboardOnlyFocusRing = () => {
  document.body.addEventListener('mousedown', () => {
    document.body.classList.add('focusWithMouse');
  });

  document.body.addEventListener('keydown', function (event) {
    if (event.keyCode === 9) {
      document.body.classList.remove('focusWithMouse');
    }
  });
};

const IdleWarningContainer = () => {
  const { user } = useCurrentUser();
  return user ? <IdleWarningModal /> : null;
};

const App = () => {
  console.log(`ClientPortal v${process.env.REACT_APP_VERSION || '0.0.0'}`);
  const flags = useFlags();
  const [, setCookie] = useCookies(['launch_darkly_flags']);

  useEffect(() => {
    keyboardOnlyFocusRing();
    segment.init();
    sentry.init();

    // Logic for tvScientific script to prevent from firing multiple times
    if (env.isProd()) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
        (function () {
          var p, s, d, w;
          d = document;
          w = window.location;
          p = d.createElement('IMG');
          s = w.protocol +
              '//tvspix.com/t.png?&t=' +
              new Date().getTime() +
              '&l=tvscientific-pix-o-92deb6b2-a1c1-40f1-8dd6-03381eeedad7&u3=' +
              encodeURIComponent(w.href);
          p.setAttribute('src', s);
          p.setAttribute('height', '0');
          p.setAttribute('width', '0');
          p.setAttribute('alt', '');
          p.style.setProperty('display', 'none');
          p.style.setProperty('position', 'absolute');
          p.style.setProperty('visibility', 'hidden');
          d.body.appendChild(p);
        })();
      `;
      document.head.appendChild(script);
    }
  }, []);

  // Add Launch Darkly flags to cookies so the backend has acc  ess to them
  useEffect(() => {
    const { hostname } = browserWindow.location;
    const domain = getCookieDomain(hostname);

    const flagsArray = Object.keys(flags).reduce((acc, key) => {
      if (!!flags[key]) {
        return [...acc, key];
      }
      return acc;
    }, []);
    setCookie('launch_darkly_flags', flagsArray, { path: '/', domain });
  }, [flags, setCookie]);

  return (
    // Disable Glia by default
    <div id="App" className="sm_cobrowsing_hidden_field">
      <HelmetProvider>
        <Helmet>
          <script src={ICON_SCRIPT} SameSite="None"></script>
          <script src="https://cdn.levelaccess.net/accessjs/YW1wMTMxOTg/access.js"></script>
          {(env.isProd() || env.isStage() || env.getEnv() === 'sand') && (
            <script src="https://api.salemove.com/salemove_integration.js"></script>
          )}
        </Helmet>

        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <CurrentUserProvider waitToRender>
              <SystemStatusProvider>
                <IdleWarningContainer />
                <CookiesProvider>
                  <BrowserRouter>
                    <CompatRouter>
                      <CurrentHomeProvider>
                        <UserTracksProvider>
                          <ThemeProvider theme={muiTheme}>
                            <NotificationBannerProvider>
                              <AppRouter />
                            </NotificationBannerProvider>
                          </ThemeProvider>
                        </UserTracksProvider>
                      </CurrentHomeProvider>
                    </CompatRouter>
                  </BrowserRouter>
                </CookiesProvider>
              </SystemStatusProvider>
            </CurrentUserProvider>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </ErrorBoundary>
      </HelmetProvider>
      <ToastContainer />
    </div>
  );
};

export default withLDProvider({ clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || '' })(App);
