import React, { Fragment, useCallback, useEffect } from 'react';
import { Navigate } from 'react-router-dom-v5-compat';
import { Button, Container, Loader, NotFoundBlock, useAsync, useWindowSize } from '@hometap/htco-components';

import useApplicants from 'apps/application/hooks/useApplicants';
import useApplication from 'apps/application/hooks/useApplication';
import useTrackDocuments from 'apps/documents/hooks/useTrackDocuments';
import { fetchOffer } from 'apps/offers/data/offerRequests';
import { fetchInvestmentSummary } from 'apps/settlement/data/settlementRequests';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import useNotificationBannerContext from 'hooks/useNotificationBannerContext';
import useTrack from 'hooks/useTrack';
import useTrackTodos from 'hooks/useTrackTodos';
import useInvestmentStatementList from 'apps/quarterly-statement/hooks/useInvestmentStatementList';
import { useEnableGlia, useGliaIdentification } from 'hooks/useGlia';

import sentry from 'utils/sentry';
import { readReceiptEligibleDocumentKinds } from 'utils/document';
import { getInvestmentDashboardUrl } from 'utils/links';
import TrackDetailsProgressStepper from './components/TrackDetailsProgressStepper/TrackDetailsProgressStepper';
import TrackDetailsSidebar from './components/TrackDetailsSidebar/TrackDetailsSidebar';
import TrackDetailsResourceList from './components/TrackDetailsSidebar/components/TrackDetailsResourceList/TrackDetailsResourceList';
import TrackDetailsStageSection from './components/TrackDetailsStageSection/TrackDetailsStageSection';
import { fetchTrackRundowns } from './data/trackDetailsRequests';
import Footer from 'components/Footer/Footer';
import { config, isTrackFunded } from './utils/config';
import { TRACK_STAGE_TO_UI_STAGE } from './utils/constants';
import { tagIDD } from 'apps/documents/hooks/useTrackDocuments';

import {
  getDefaultTrackDetailsSidebarBottomComponent,
  getDefaultTrackDetailsSidebarTopComponent,
  getResourceSections,
} from './utils/sidebar';
import { DOCUMENT_TAGS } from 'apps/documents/constants';
import SecondaryHeader from 'components/Headers/SecondaryHeader/SecondaryHeader';

import './TrackDetailsController.scss';

const TrackDetailsController = () => {
  const { track, isRolloutFlagEnabled, loading: isTrackLoading } = useTrack();
  const {
    documents,
    loading: isTrackDocumentsLoading,
    handleViewDocument: handleViewInvestmentDisclosureDocument,
    fetchUnreadDocuments,
  } = useTrackDocuments({ fetchImmediately: true });
  const { application, ...applicationState } = useApplication();
  const { applicants, ...applicantsState } = useApplicants();
  const { results: investmentSummary, ...fetchInvestmentSummaryAsync } = useAsync(fetchInvestmentSummary);
  const { results: rundowns = [], ...fetchTrackRundownsAsync } = useAsync(fetchTrackRundowns);
  const { results: unreadDocuments, ...fetchUnreadDocumentsAsync } = useAsync(fetchUnreadDocuments);
  const { showNotificationBanner } = useNotificationBannerContext();
  const { isScreenSize } = useWindowSize();

  useEnableGlia(true);
  useGliaIdentification();

  const { statements, fetchInvestmentStatementListAsync } = useInvestmentStatementList({
    investmentId: track?.active_investment_id,
  });

  const { error: statementsError } = fetchInvestmentStatementListAsync;

  const { todos = [], fetchTodos } = useTrackTodos();

  // Only used for an offer rundown
  const { results: offer, ...fetchOfferAsync } = useAsync(fetchOffer);
  // TODO: Add other async data loading states here or possibly more fine grained loading states for
  // where we know todos will be located. E.g., show place holder card where we know the
  // application card would be located.
  const isLoading =
    isTrackLoading ||
    applicationState.isLoading ||
    applicantsState.isLoading ||
    isTrackDocumentsLoading ||
    fetchInvestmentStatementListAsync.loading;

  const loadAsyncData = useCallback(async () => {
    const asyncs = [
      fetchTrackRundownsAsync.execute(track?.id),
      fetchTodos(track?.id),
      fetchUnreadDocumentsAsync.execute(track?.id),
    ];

    if (track?.active_application_id) {
      asyncs.push(applicationState.executeFetchApplication({ applicationId: track?.active_application_id }));
      asyncs.push(applicantsState.executeFetchApplicants({ applicationId: track?.active_application_id }));
    }

    if (track?.active_offer_rundown_id) {
      asyncs.push(fetchOfferAsync.execute(track?.active_offer_rundown_id));
    }

    if (track?.active_investment_id) {
      asyncs.push(fetchInvestmentSummaryAsync.execute(track?.active_investment_id));
    }

    return await Promise.all(asyncs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track?.active_application_id]);

  useEffect(() => {
    // TODO: This should be moved into the useTrackDocuments hook
    if (unreadDocuments?.length > 0) {
      // only show banner for documents.type === final_investment
      const unreadIDDs = unreadDocuments.filter(readReceiptEligibleDocumentKinds);

      if (unreadIDDs.length === 0) return;

      const unreadIdd = unreadIDDs.reduce((prev, current) => {
        const prevDate = new Date(prev?.sent_to_homeowner_tag);
        const currDate = new Date(current?.sent_to_homeowner_tag);
        return prevDate > currDate ? prev : current;
      }, {});

      showNotificationBanner({
        icon: 'file-empty',
        title: 'Investment Disclosure is available.',
        supportingText: 'Please review as soon as possible.',
        onDismiss: () =>
          tagIDD({ documentId: unreadIdd?.id, trackId: track?.id, tag: DOCUMENT_TAGS.DISMISSED_BY_HOMEOWNER }),
        rightSideContent: (
          <Button
            onClick={() => handleViewInvestmentDisclosureDocument(unreadIdd)}
            theme="alternative"
            className="TrackDetailsInvestmentDisclosureBannerActionButton"
          >
            View Investment Disclosure
          </Button>
        ),
      });
    }
  }, [
    isRolloutFlagEnabled,
    handleViewInvestmentDisclosureDocument,
    showNotificationBanner,
    track?.id,
    unreadDocuments,
  ]);

  useEffect(() => {
    if (!isTrackLoading && track) {
      loadAsyncData();
    }
  }, [isTrackLoading, track, loadAsyncData]);

  if (isLoading) {
    return <Loader type="dot-pulse" className="TrackDetailsLoader" />;
  }

  if (!track) {
    return <NotFoundBlock />;
  }
  const asyncData = { rundowns, application, offer, track, applicants, investmentSummary, asyncTodos: todos };
  const currentUIStage = TRACK_STAGE_TO_UI_STAGE[track?.current_stage];
  const isFunded = isTrackFunded(track);

  const allUIProgressStepperStages = Object.keys(config).filter(key => !config[key].showWithoutOtherStages);

  if (!currentUIStage && !isFunded) {
    // NOTE: ClosedLost tracks are handled in the useTrack() hook. This would account for any other
    // unaccounted stages added to the backend and we would want to be made aware that the new stage slipped
    // through the cracks.
    sentry.logWarning(`A track stage has been unaccounted for on the front-end: ${track?.current_stage}.`);
    return <Navigate to={getInvestmentDashboardUrl()} />;
  }

  const currentUIStageConfig = config[currentUIStage.key];
  let lastActiveStageIndex = Object.keys(config).findIndex(stage => stage === currentUIStage.key);
  const shouldHoldOnPreviousStage =
    currentUIStageConfig.determineIsActive && !currentUIStageConfig.determineIsActive(asyncData);
  if (shouldHoldOnPreviousStage && lastActiveStageIndex > 0) {
    lastActiveStageIndex = lastActiveStageIndex - 1;
  }

  let renderedStagesConfigs;
  if (currentUIStageConfig.showWithoutOtherStages) {
    renderedStagesConfigs = [currentUIStageConfig];
  } else {
    renderedStagesConfigs = Object.values(config)
      .slice(lastActiveStageIndex, config.length)
      .filter(uiStage => !uiStage.showWithoutOtherStages);
  }

  const renderedStages = renderedStagesConfigs.map((section, i) => {
    // first section in the list should be active
    const isActive = i === 0;

    const todos = section
      .getTodos({ isActive, ...asyncData, isRolloutFlagEnabled })
      .filter(Boolean)
      .filter(({ isComplete }) => (typeof isComplete === 'function' ? !isComplete(asyncData) : !isComplete));

    return (
      <Fragment key={`${section.title}-${i}`}>
        <TrackDetailsStageSection
          isActive={isActive}
          sectionConfigData={section}
          sectionContext={asyncData}
          fetchAsyncTodos={fetchTodos}
          todos={todos}
        />
        {isActive && section.extraSectionContent && section.extraSectionContent}
      </Fragment>
    );
  });

  return (
    <ScrollToTop>
      <div className="TrackDetailsControllerContainer PageBodyCenteredContainer">
        <SecondaryHeader label={'Investment ID:'} value={track?.friendly_id} />
        <Container className="TrackDetailsController">
          <div className="TrackDetailsControllerSidebarContainer">
            <TrackDetailsSidebar
              topContent={getDefaultTrackDetailsSidebarTopComponent({ track, isScreenSize })}
              middleContent={
                <TrackDetailsResourceList
                  resources={getResourceSections({
                    rundowns,
                    offer,
                    statements,
                    statementsError,
                    documents,
                    track,
                    showDocumentsSection: true,
                    isRolloutFlagEnabled,
                    isScreenSize,
                  })}
                  track={track}
                  isRolloutFlagEnabled={isRolloutFlagEnabled}
                />
              }
              bottomContent={getDefaultTrackDetailsSidebarBottomComponent({ track, isScreenSize })}
            />
          </div>
          {lastActiveStageIndex < allUIProgressStepperStages.length && (
            <div className="TrackDetailsControllerProgressStepperContainer">
              <TrackDetailsProgressStepper steps={allUIProgressStepperStages} activeIndex={lastActiveStageIndex} />
            </div>
          )}
          <div>{renderedStages}</div>
        </Container>
        <Footer />
      </div>
    </ScrollToTop>
  );
};

export default TrackDetailsController;
